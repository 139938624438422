import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    getInfomationAccountFromToken,
    checkRoleUser
} from '../../../utils/helper';
import { role } from '../../../utils/define';
import { URI_INTERNAL } from '../../../utils/pathLocations';

const MenuSidebar = () => {
    const [t] = useTranslation();
    const account = getInfomationAccountFromToken();

    const navSystem = [
        {
            to: URI_INTERNAL.ACCOUNT_PATH,
            name: 'Tài khoản',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.EMAILCONFIG_PATH,
            name: 'Email gửi thông báo',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.LOG_PATH,
            name: 'Xem log',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.CONTACT_PATH,
            name: 'Liên hệ',
            exact: false,
            roles: [role.Admin, role.Manager]
        }
    ];
    const navReport = [
        {
            to: URI_INTERNAL.REPORT_POST_PATH,
            name: 'Thống kê bài viết',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.LOGIN_HISTORY_PATH,
            name: 'Báo cáo người dùng đăng nhập',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_SYNTHETIC_PATH,
            name: 'Báo cáo bài viết tổng hợp',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_EXCEL_CREATE_BY,
            name: 'Báo cáo bài viết theo tác giả',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_VIEW_DETAIL,
            name: 'Báo cáo lượt xem chi tiết',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_BY_DATETYPE,
            // to: URI_INTERNAL.REPORT_POST_PUBLISHTIME_DATATYPE_PATH,
            name: 'Thống kê theo loại bài viết',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_EXCEL_QUANTITY_CREATE_BY,
            name: 'Thống kê số lượng đăng theo tác giả',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_POST_WEBANALYSIS_PATH,
            name: 'Thống kê truy cập tin bài',
            exact: false,
            roles: [role.Admin, role.Manager]
        },
        {
            to: URI_INTERNAL.REPORT_ADVERTISING_ANALYSIS_PATH,
            name: 'Thống kê truy cập quảng cáo',
            exact: false,
            roles: [role.Admin, role.Manager]
        }
    ];
    const navHome = [
        {
            to: URI_INTERNAL.CATEGORY_PATH,
            name: 'Danh mục',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.GIAODIEN_PATH,
            name: 'Thành phần giao diện',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.BANNER_PATH,
            name: 'Quảng cáo',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.PARTNER_PATH,
            name: 'Đối tác',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.SLIDE_PATH,
            name: 'Slide ảnh',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        // {
        //     to: URI_INTERNAL.HTML5BANNER_PATH,
        //     name: 'File Html 5',
        //     exact: false,
        //     roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        // }
    ];

    const navContent = [
        {
            to: URI_INTERNAL.POST_PATH,
            name: 'Tin tức',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        // {
        //     to: URI_INTERNAL.INTRODUCE_PATH,
        //     name: 'Giới thiệu',
        //     exact: false,
        //     roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        // },
        {
            to: URI_INTERNAL.VIDEO_PATH,
            name: 'Video',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.LIBRARY_IMAGE_PATH,
            name: 'Thư viện ảnh',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            to: URI_INTERNAL.NEWSCRAWLER_PATH,
            name: 'Kho tin crawler',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
 
    ];

    const navLanding = [
        {
            to: URI_INTERNAL.LANDING_COMPONENT_PATH,
            name: 'Landing component',
            exact: false,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        }
    ];

    const obMenus = [
        {
            titleParentMenu: 'Hệ thống',
            icon: 'nav-icon fas fa-cog',
            childMenu: navSystem,
            roles: [role.Admin, role.Manager]
        },
        {
            titleParentMenu: 'Thành phần trang chủ',
            icon: 'nav-icon fas fa-home',
            childMenu: navHome,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            titleParentMenu: 'Nội dung bài viết',
            icon: 'nav-icon fas fa-newspaper',
            childMenu: navContent,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            titleParentMenu: 'Landing Page',
            icon: 'nav-icon fas fa-pager',
            childMenu: navLanding,
            roles: [role.Admin, role.Manager, role.Publisher, role.Editor]
        },
        {
            titleParentMenu: 'Báo cáo',
            icon: 'nav-icon fas fa-table',
            childMenu: navReport,
            roles: [role.Admin, role.Manager]
        }
    ];
    const havePermission = (roles) => {
        const user = getInfomationAccountFromToken();
        if (user) {
            return checkRoleUser(roles, user);
        }
        return false;
    };

    const [configData, setConfigData] = useState([]);

    const getData = () => {
        fetch('config.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(function (response) {
            console.log(response)
            return response.json();
        }).then(function (myJson) {
            console.log(myJson);
            setConfigData(myJson);
        });
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/" className="brand-link">
                <img
                    src="/img/logo.png"
                    alt="AdminLTE Logo"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: '.8' }}
                />
                <span className="brand-text font-weight-light">{configData.NAME_OF_PROJECT}</span>
            </Link>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src="/img/default-profile.png"
                            className="img-circle elevation-2"
                            alt="User"
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {account.email ? account.email : ''}
                        </Link>
                    </div>
                </div>
                <nav className="mt-2" style={{ overflowY: 'hidden' }}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <NavLink to="/" exact className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>{t('menusidebar.label.dashboard')}</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/profile" exact className="nav-link">
                                <i className="nav-icon fas fa-user" />
                                <p>{t('menusidebar.label.profile')}</p>
                            </NavLink>
                        </li>
                        {obMenus.map((item) => (
                            <>
                                {havePermission(item.roles) ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink
                                                to
                                                exact
                                                className="nav-link"
                                            >
                                                <i
                                                    style={{
                                                        marginRight: '10px'
                                                    }}
                                                    className={item.icon}
                                                />
                                                <p>
                                                    {item.titleParentMenu}
                                                    {item.childMenu.length >
                                                        0 ? (
                                                        <i className="fas fa-angle-left right" />
                                                    ) : null}
                                                </p>
                                            </NavLink>
                                            <ul
                                                className="nav nav-treeview"
                                                style={{
                                                    display: 'none'
                                                }}
                                            >
                                                {item.childMenu.map(
                                                    ({ to, name, roles }) => (
                                                        <>
                                                            {havePermission(
                                                                roles
                                                            ) ? (
                                                                <li className="nav-item">
                                                                    <NavLink
                                                                        to={to}
                                                                        className="nav-link"
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                marginRight:
                                                                                    '5px'
                                                                            }}
                                                                            className="far fa-circle nav-icon"
                                                                        />
                                                                        <p>
                                                                            {
                                                                                name
                                                                            }
                                                                        </p>
                                                                    </NavLink>
                                                                </li>
                                                            ) : null}
                                                        </>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                    </>
                                ) : null}
                            </>
                        ))}
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default MenuSidebar;
