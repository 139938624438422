import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { newsCrawlerPagingAction } from '@store/reducers/newsCrawler';
import Table from 'react-bootstrap/Table';
import { Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useMutation, useQuery } from 'react-query';
import { Post, Get } from '@app/utils/httpProvider';
import { POST_API } from '@app/utils/apiUrl';
import { toast } from 'react-toastify';
// import ReactExport from 'react-export-excel';
import {
    createUrlWithParam,
    getDateTimeISOString,
    formatDateTimeWithoutHourMoment,
    exportExcel
} from '../../utils/helper';
import { URI_INTERNAL } from '../../utils/pathLocations';

const ReportPostPublishTimeAndDataType = () => {

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['reportPostByPublishTimeAndDataTypeData'],
        () =>
            Get(
                POST_API.STATISTIC_POST_BY_PUBLISH_TIME_AND_DATA_TYPE_API,
                null,
                null
            )
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">
                                {' '}
                                Thống kê theo loại bài viết
                            </h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between mb-2">
                                <div>{}</div>
                                <NavLink
                                    className="nav-link-reject nav-link"
                                    to
                                    onClick={() => exportExcel(`baocao_theoloaibaiviet_${Date.now()}`)}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-info"
                                    >
                                        Xuất file Excel
                                    </button>
                                </NavLink>

                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table
                                                id="table-to-xls"
                                                striped
                                                bordered
                                                hover
                                            >
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th>Loại bài viết</th>
                                                        <th>Tuần này</th>
                                                        <th>Tuần trước</th>
                                                        <th>Tháng này</th>
                                                        <th>Tháng trước</th>
                                                        <th>Năm nay</th>
                                                        <th>Tổng số</th>
                                                    </tr>
                                                </thead>
                                                {data != null && data !== undefined && data.$values != null &&
                                                    data.$values !== undefined &&
                                                    data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.$values.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index + 1}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.$values != null &&
                                                                                item.$values !== undefined &&
                                                                                item.$values.length > 0 ? (item.$values[0].dataType === "information" ? "Tin tức" : (item.$values[0].dataType === "video" ? "Video" : "Ảnh")) : null
                                                                        }
                                                                    </td>
                                                                    {
                                                                        item.$values != null &&
                                                                            item.$values !== undefined &&
                                                                            item.$values.length > 0 ? item.$values.map(
                                                                                (itemInside, indexInside) => (
                                                                                    <td>
                                                                                        {itemInside.getCount}
                                                                                    </td>
                                                                                )
                                                                            ) : null
                                                                    }
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportPostPublishTimeAndDataType;
