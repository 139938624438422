import React, { useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
    messageError,
    messageToast,
    status200,
    acceptImage
} from '../../utils/define';
import { BaseGatewayImage, PARTMER_API } from '../../utils/apiUrl';
import ReactSpinner from '../../components/reactSpinner/ReactSpinner';

const schema = yup.object().shape({
    name: yup.string().required(messageError.emptyMessage),
    redirectLink: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage)
});

const PartnerEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    const location = useLocation();
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const { isLoading, isError, error, data } = useQuery([id], () =>
        Get(PARTMER_API.GET_ITEM_PARTMER, id)
    );

    const editFunc = useMutation((value) =>
        Put(PARTMER_API.UPDATE_PARTMER, value.id, value.data)
    );

    const handleEdit = async (values) => {
        const model = values;

        const modelUpdate = {
            data: model,
            id: id
        };
        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    queryClient.setQueryData(id, model);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin đối tác</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : data == null ? (
                    <div>Đối tác không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            name: data && data.name ? data.name : '',
                            redirectLink:
                                data && data.redirectLink
                                    ? data.redirectLink
                                    : '',
                            orderNumber:
                                data && data.orderNumber ? data.orderNumber : 0,
                            isActive:
                                data && data.isActive ? data.isActive : false,
                            imagePath:
                                data && data.imagePath ? data.imagePath : '',
                            imageThumbnail:
                                data && data.imageThumbnail
                                    ? data.imageThumbnail
                                    : ''
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tên *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tên"
                                            type="text"
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            isInvalid={!!errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Link đối tác
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Link đối tác"
                                            type="text"
                                            name="redirectLink"
                                            value={values.redirectLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.redirectLink}
                                        />
                                        {/* <Form.Control.Feedback type="invalid">
                                        {errors.redirectLink}
                                    </Form.Control.Feedback> */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            values={
                                                values.imageThumbnail
                                                    ? [
                                                        BaseGatewayImage +
                                                        values.imageThumbnail
                                                    ]
                                                    : []
                                            }
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setFieldValue(
                                                        'imagePath',
                                                        files[0].filePath,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        files[0].thumbPath,
                                                        true
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'imagePath',
                                                        '',
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        '',
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái kích hoạt
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Trạng thái"
                                            name="isActive"
                                            checked={values.isActive}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Sắp xếp
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="orderNumber"
                                            placeholder="Sắp xếp"
                                            value={values.orderNumber}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );
};

export default PartnerEdit;
