import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@components';
import PropTypes from 'prop-types';
import { Form, Row, ProgressBar } from 'react-bootstrap';
import { BaseGatewayImage, FILE_MANAGER_API } from '@app/utils/apiUrl';
import axios from 'axios';
import { getToken } from '@app/utils/helper';
import { toast } from 'react-toastify';

// hàm để upload file video lên server
const uploadFile = async ({ fileUpload, setCompleted }) => {
    const token = getToken();
    const config = {
        onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted);
            setCompleted(percentCompleted);
        },
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `bearer ${token}`
        }
    };
    const data = new FormData();
    data.append('file', fileUpload);
    try {
        const res = await axios.post(FILE_MANAGER_API.UPLOAD, data, config);
        return res;
    } catch (error) {
        const errorMessage = error.response.data.message;
        console.log(errorMessage);
        return errorMessage;
    }
};
const VidepInput = (props) => {
    const { value, enableReinitialize, isInvalid, errorsValue, onChangeProp } = props;
    const inputUpload = useRef();
    const [completed, setCompleted] = useState(0); 
    const [videoUrl, setVideoUrl] = useState('');

    useEffect(() => {
        if (
            (value && value.length > 0 && videoUrl.length === 0) ||
            enableReinitialize
        ) {
            setVideoUrl(value);
        }
    }, [value]);

    function validURL(str) {
        const pattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
            'i'
        ); // fragment locator
        return !!pattern.test(str);
    }

    const renderUrlVideo = (urlVideo) => {
        if (!validURL(urlVideo)) return BaseGatewayImage + urlVideo;
        const url = new URL(urlVideo);
        if (url.hostname === 'www.youtube.com' && urlVideo.includes('watch?v=')
        ) {
            return urlVideo.replace('watch?v=', 'embed/');
        }
        // return urlVideo;
        return  urlVideo;
    };
    const handleUploadFile = async (event) => {
        const fileUpload = event.target.files[0];
        const fileSize = fileUpload.size / 1024 / 1024; // in MiB
        if (fileSize > 200) {
            toast.error("Kích thước tệp video quá lớn, hãy thử lại với tệp nhỏ hơn 200MB");
        } else {
            const result = await uploadFile({ fileUpload, setCompleted });
            const fileUpdated = result.data.$values[0];
            // setVideoUrl(BaseGatewayImage + fileUpdated.filePath);
            // onChangeProp(BaseGatewayImage + fileUpdated.filePath);
            setVideoUrl(fileUpdated.filePath);
            onChangeProp(fileUpdated.filePath);
            setCompleted(0);
            toast.success("Tải video thành công !");
        }
    };
    const isUploaded = videoUrl && completed === 0;
    return (
        <>
            {console.log("videoUrl", videoUrl)}
            <iframe
                className="mb-1"
                // style={{ display: validURL(videoUrl) ? 'block' : 'none' }}
                style={{ display: videoUrl ? 'block' : 'none', }}
                src={renderUrlVideo(videoUrl)}
                title="This is a unique title"
            >
                { }
            </iframe>
            <Row className="col">
                <Form.Control
                    className="w-auto"
                    placeholder="Đường dẫn video"
                    type="text"
                    name="videoLink"
                    value={videoUrl}
                    onChange={(e) => {
                        setVideoUrl(e.target.value);
                        onChangeProp(e.target.value);
                    }}
                    isInvalid={isInvalid}
                />
                <Form.Control
                    type="file"
                    hidden
                    accept="video/*"
                    ref={inputUpload}
                    onChange={(e) => handleUploadFile(e)}
                />
                <Button className="w-auto ms-2" onClick={() => inputUpload.current.click()}>Chọn file</Button>
                <Form.Control.Feedback type="invalid">
                    {errorsValue}
                </Form.Control.Feedback>
            </Row>
            <Row className="col">
                <ProgressBar
                    className="progress-striped active w-100"
                    style={{
                        display: isUploaded ? 'none' : 'flex'
                    }}
                    variant="success"
                    animated
                    now={completed}
                />
            </Row>
        </>
    );
};

VidepInput.propTypes = {
    value: PropTypes.string,
    enableReinitialize: PropTypes.bool,
    onChangeProp: PropTypes.func
};

VidepInput.defaultProps = {
    enableReinitialize: false,
    onChangeProp: () => { },
    value: ''
};

export default VidepInput;
